// Import our custom CSS
require("../scss/styles.scss")
require("../scss/baseapp.scss")

// Import all of Bootstrap's JS
// eslint-disable-next-line no-unused-vars
import $ from "jquery"
// eslint-disable-next-line no-unused-vars
import Popper from "popper.js"
import * as bootstrap from "bootstrap"
import { Toast } from "bootstrap"

import htmx from "htmx.org"
import "socket.io-client"

window.bootstrap = bootstrap

function initBootstrapGenerics() {
  const tooltipTriggerList = document.querySelectorAll(
    '[data-bs-toggle="tooltip"]'
  )
  const tooltipList = [...tooltipTriggerList].map(
    (tooltipTriggerEl) => new bootstrap.Tooltip(tooltipTriggerEl)
  )
}

window.addEventListener("DOMContentLoaded", () => {
  initBootstrapGenerics()
})

function createToast(message, delay = 2000) {
  // Clone the template
  const element = htmx.find("[data-app-toast-template]").cloneNode(true)

  // Remove the data-toast-template attribute
  delete element.dataset.appToastTemplate

  // Set the CSS class
  element.className += " " + message.tags

  // Set the text
  htmx.find(element, "[data-app-toast-body]").innerText = message.message

  htmx.find("[data-app-toast-container]").appendChild(element)

  const toast = new bootstrap.Toast(element, { delay: 2000 })
  toast.show()
}

htmx.on("messages", (event) => {
  event.detail.value.forEach(createToast)
})

htmx.on("htmx:sendError", (event) => {
  createToast({
    message: "Network error. Please try again later.",
    tags: "text-bg-danger",
  })
})

window.htmx = htmx
window.bsToast = Toast
window.createToast = createToast
